/* eslint-disable no-undef */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { eventClick } from "../../../utils/analytics";

const Main = () => {

  return (
    <section
      className="p-8 min-h-screen bg-cover"
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url('/novo-bg.jpg')", // Use a imagem do fundo desejada
        backgroundSize: "cover",
        backgroundPosition: "55% center",
      }}
    >
      <div id="mainCopy" className="md:p-10 my-2 md:my-6 md:mx-8 w-3/4 md:w-1/2 sm:pt-24">
        <div className="mb-8 drop-shadow-lg z-20">
          <div className="space-y-10">
            <h1 id="TitleCopy" className="md:text-6xl text-4xl font-black text-accent-300">
              Psicóloga Online de Mulheres e Mães
            </h1>
            <p id="DescribeCopy" className="md:text-2xl text-xl text-accent-500">
              Te ajudo a entender as tretas e as potências de ser mulher nesse
              mundo. Vamos falar sobre sua saúde mental com afeto?{" "}
              <span className="text-accent-600 font-black block md:inline md:mt-0 mt-2 md:text-2xl text-xl">
                Agende sua sessão e comece a sua jornada do autoconhecimento!
              </span>
            </p>
          </div>
        </div>
        <div id="mainCTAs" className="grid gap-4 lg:grid-cols-2 font-black text-xl text-white">
          <a
            href="/agendamento"
            onClick={() => eventClick('Escolha seu horário')}
            target="_blank"
            rel="noreferrer"
            className="bg-primary-500 py-2 px-4 rounded-lg shadow-lg hover:bg-primary-800 flex items-center justify-center text-center"
          >
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-3" />
            Escolha seu horário
          </a>
          <a
            href="https://wa.me/message/JISV7IDLOVNRJ1"
            onClick={() => eventClick('Fale com a psi')}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-secondary-500 py-2 px-4 rounded-lg shadow-lg hover:bg-secondary-600 flex items-center justify-center text-center"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="mr-3" />
            Fale com a Psi
          </a>
        </div>
      </div>
      <div
        className="flex justify-center mt-10 mb-2 cursor-pointer animate-bounce"
        id="nextSection"
        onClick={() =>
          document
            .getElementById("servicos")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          className="text-white text-4xl md:text-6xl font-bold"
        />
      </div>
    </section>
  );
};

export default Main;
