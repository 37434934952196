import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faNewspaper,
  faLaptop,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { eventClick } from "../../../utils/analytics";

const Topbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar se o menu está aberto ou fechado

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav>
      <div className="lg:hidden flex justify-between items-center">
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleMenu}
          className="text-white cursor-pointer"
        />
      </div>

      <ul
        className={`${
          isMenuOpen ? "flex" : "hidden"
        } flex-col lg:flex lg:flex-row lg:space-x-6 mt-4 lg:mt-0 text-white items-start w-full`}
      >
        <li>
          <a
            className="hover:text-secondary flex items-center space-x-2 py-2"
            href="https://www.instagram.com/psi.annacarolina/"
            onClick={() => eventClick("Instagram")}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
            <span className="ml-2">Instagram</span>
          </a>
        </li>
        <li>
          <a
            className="hover:text-secondary flex items-center space-x-2 py-2"
            href="#servicos"
          >
            <FontAwesomeIcon icon={faLaptop} />
            <span className="ml-2">Serviços</span>
          </a>
        </li>
        <li>
          <a
            className="hover:text-secondary flex items-center space-x-2 py-2"
            href="#sobre"
          >
            <FontAwesomeIcon icon={faUser} />
            <span className="ml-2">Conheça a Psi</span>
          </a>
        </li>
        <li>
          <a
            className="hover:text-secondary flex items-center space-x-2 py-2"
            href="https://msha.ke/psi.annacarolina"
            onClick={() => eventClick("Psi na mídia")}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faNewspaper} />
            <span className="ml-2">Psi na Mídia</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Topbar;
