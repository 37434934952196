import React from "react";

const Logobar = () => {
  return (
    <div className="flex items-center md:mr-6">
      <a href="/">
        <img
          className="sm:h-16 h-9 mr-6"
          src="/white-logo.png"
          alt="Logotipo branco da Psicóloga Anna Carolina Guedes"
        />
      </a>
    </div>
  );
};

export default Logobar;
