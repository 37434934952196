/* eslint-disable no-undef */
export const eventClick = (label) => {
  if (typeof gtag === 'function') {
    gtag('event', 'click', {
      'event_category': 'Link Click',
      'event_label': label
    });
  } else {
    console.error('gtag is not defined');
  }
};