import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faBrain, faCode } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-primary-500 p-8 text-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 text-center gap-8 font-black gap-x-5">
          <div>
            <FontAwesomeIcon icon={faCopyright} className="text-2xl mb-2" />
            <p>&copy; 2024 Todos os direitos reservados</p>
          </div>
          <div>
            <FontAwesomeIcon icon={faBrain} className="text-2xl mb-2" />
            <p>Psicóloga Anna Carolina Silva Guedes de Araujo</p>
            <p>CRP - 15/4579</p>
          </div>
          <div>
            <FontAwesomeIcon icon={faCode} className="text-2xl mb-2" />
            <p>
              Desenvolvido por{" "}
              <a
                href="https://github.com/RodrigoBastos"
                target="_blank"
                rel="noreferrer"
                className="text-accent-500 hover:text-accent-600 hover:underline"
              >
                @RodrigoBastos
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
