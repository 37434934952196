import React from "react";
import Divider from "./Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";

const Aboutme = () => {
  return (
    <div className="bg-accent-100">
      <section id="sobre" className="info-section">
        <div>
          <h2 className="">Conheça a Psi</h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="gap-y-10 order-2 lg:order-1">
            <div className="about-card">
              <div className="space-y-6">
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faThumbtack} className="mr-3 text-accent-600" />
                  <h3>Olá, sou a Anna!</h3>
                </div>

                <p>
                  <span className="font-black">
                    Meu objetivo é cuidar da saúde mental da mulher, com um foco
                    especial na maternidade.
                  </span>{" "}
                  Tema que atravessa as nossas vidas, de diferentes formas –
                  desde a relação com a mãe, a decisão sobre ter filhos, até a
                  gestação e o puerpério. Por isso, acredito que o apoio
                  psicológico é essencial para o seu bem-estar e fortalecimento
                  dessa jornada.
                </p>
                <Divider />
                <p>
                  <span className="font-black">
                    Nosso trabalho será feito com muito afeto e parceria.
                  </span>{" "}
                  Com uma escuta acolhedora, vamos explorar os sentimentos dessa
                  experiência e encontrar maneiras de lidar com as dificuldades
                  e potencialidades de ser mulher de forma mais leve e
                  empoderada. Quero te ajudar a conquistar autoconhecimento e
                  confiança, para que você viva plenamente todas as facetas da
                  sua vida.
                </p>
              </div>
            </div>
          </div>
          <div className="order-1 lg:order-2 flex justify-center items-center">
            <img
              src="./aboutme.jpg"
              alt="Foto da psicologo Anna Carolina."
              className="border-leaf"
            ></img>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Aboutme;
