import "./App.css";
import Bar from "./components/grid/header/Bar";
import Content from "./components/grid/banner/Content";
import Services from "./components/Services";
import Aboutme from "./components/Aboutme";
import Divider from "./components/Divider";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Bar />
      <Content />
      <Divider />
      <Services />
      <Divider />
      <Aboutme />
      <Divider />
      <Footer />
    </>
  );
}

export default App;
