import React from "react";
import Logobar from "./Logobar";
import Topbar from "./Topbar";

const Bar = () => {
  return (
    <header className="bg-primary-500 p-6 text-white">
        <div className="container mx-auto flex justify-between items-center">
          <Logobar />
          <Topbar />
        </div>
    </header>
  );
};

export default Bar;