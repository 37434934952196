import React from "react";
import { eventClick } from "../utils/analytics";

const Services = () => {
  return (
    <section id="servicos" className="info-section">
      <h2>Serviços</h2>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
          <div className="service-card">
            <img
              className="image-card"
              src="./5.jpg"
              alt="Desenho de mulher PCD com prótese no braço direito, usando celular com a mão esquerda, vestindo lilás, com fundo azul e roxo."
            ></img>
            <div className="p-4 flex flex-col flex-grow">
              <h3>Psicoterapia Online</h3>
              <p>
                Seu bem-estar também é prioridade. Encontre um espaço para se
                ouvir e se cuidar com a terapia online. Vamos conversar?
              </p>
              <div className="mt-auto">
                <a
                  href="https://wa.me/5582996221624?text=Oi Psi! Gostaria de mais informações sobre a Psicoterapia online."
                  onClick={() => eventClick("Serviço psicoterapia online")}
                  className="mt-4 inline-block px-6 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition duration-200"
                >
                  Saiba mais
                </a>
              </div>
            </div>
          </div>
          <div className="service-card">
            <img
              className="image-card"
              src="./4.jpg"
              alt="Desenho de mulher grávida, vestindo vestido azul claro, segurando uma rosa e com a outra mão na barriga, com fundo azul e roxo."
            ></img>
            <div className="p-4 flex flex-col flex-grow">
              <h3>Pré-natal psicológico</h3>
              <p>
                O pré-natal psicológico online cuida de você, mãe, com apoio
                emocional essencial na gestação. Conheça mais e cuide do seu
                bem-estar!
              </p>
              <div className="mt-auto">
                <a
                  href="https://wa.me/5582996221624?text=Oi Psi! Gostaria de mais informações sobre o Pré-natal psicológico."
                  onClick={() => eventClick("Serviço pré-natal psicológico")}
                  className="mt-4 inline-block px-6 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition duration-200"
                >
                  Saiba mais
                </a>
              </div>
            </div>
          </div>
          <div className="service-card">
            <img
              className="image-card"
              src="./6.jpg"
              alt="Desenho de útero com clipes de metal em cada trompa, sobre fundo azul e roxo."
            ></img>
            <div className="p-4 flex flex-col flex-grow">
              <h3>Avaliação psicológica para laqueadura</h3>
              <p>
                A avaliação psicológica online para laqueadura te auxilia no
                planejamento familiar consciente. Veja como posso te apoiar
                nesse processo.
              </p>
              <div className="mt-auto">
                <a
                  href="https://wa.me/5582996221624?text=Oi Psi! Gostaria de mais informações sobre a Avaliação psicológica para laqueadura."
                  onClick={() =>
                    eventClick("Avaliação psicológica para laqueadura")
                  }
                  className="mt-4 inline-block px-6 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition duration-200"
                >
                  Saiba mais
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
